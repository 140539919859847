<template>
  <div
    class="quick-alert py-2 px-3 font-size-small d-flex align-items-center"
    :class="cssClass"
  >
    <span>{{ content }}</span>
  </div>
</template>

<script>

export default {
  name: 'QuickAlert',
  props: ['type', 'content'],
  components: {
  },
  computed: {
    cssClass () {
      return `alert-${this.alertType}`
    },
    alertType () {
      if (this.type === 'error') return 'danger'
      return this.type
    }
  }
}
</script>

<style lang="scss" scoped>
.quick-alert {
  position: fixed;
  top: 60px;
  right: 0;
  z-index: 100;
}

.slide-fade-enter-active {
  transition: all 0.5s ease-in;
}
.slide-fade-leave-active {
  transition: all 0.5s ease-in;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(100px);
  opacity: 0;
}

.alert-success {
  ::v-deep {
    .color {
      fill: #155724;
    }
  }
}

</style>
